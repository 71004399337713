@tailwind base;
@tailwind components;
@tailwind utilities;

.ant-dropdown-menu-item {
    padding: 0 !important;
}

.ant-dropdown-menu-item button {
    width: 100%;
    text-align: left;
    padding: 8px 12px;
}